button {
  &,
  &.button-primary,
  &.button-secondary,
  &.button-warn {
    font-size: 12px;
    //padding: 0 4ch 0 4ch;
    //padding: 25px 5px;
    //padding: 20px;
    padding: 25px 20px;
    height: 36px;
    border-radius: 0;
  }

  &.button-primary:not([disabled]) {
    color: rgb(var(--color-base-white)) !important;
    background-color: rgb(var(--color-primary)) !important;
    transition: 0.4s;
  }

  &[disabled] {
    transition: 0.4s;
  }

  &.button-secondary:not([disabled]) {
    color: rgb(var(--color-primary)) !important;
    background-color: rgb(var(--color-base-10)) !important;
  }

  &.button-warn:not([disabled]) {
    color: rgb(var(--color-base-white)) !important;
    background-color: rgb(var(--color-error-100)) !important;
    transition: 0.4s;
  }

  //&.mdc-button:active {
  //  background-color: white !important;
  //}
}

.primary-fab,
.accent-fab,
.warn-fab {
  height: 40px !important;
  width: 40px !important;
  box-shadow: none !important;
}

.primary-fab:not([disabled]) {
  --mdc-fab-small-container-color: rgb(var(--color-shade-20)) !important;
  --mat-fab-small-foreground-color: rgb(var(--color-primary)) !important;
  --mat-mdc-fab-color: rgb(var(--color-primary)) !important;
}
.accent-fab:not([disabled]) {
  --mdc-fab-small-container-color: rgb(var(--color-shade-20)) !important;
  --mat-fab-small-foreground-color: rgb(var(--color-primary)) !important;
  --mat-mdc-fab-color: rgb(var(--color-primary)) !important;
}
.warn-fab:not([disabled]) {
  --mdc-fab-small-container-color: rgb(var(--color-error-100), 0.1) !important;
  --mat-fab-small-foreground-color: rgb(var(--color-error-100)) !important;
  --mat-mdc-fab-color: rgb(var(--color-error-100)) !important;
}

.primary-fab,
.accent-fab,
.warn-fab {
  &[disabled] {
    --mdc-fab-small-container-color: rgb(var(--color-base-40), 0.15) !important;
    --mat-mdc-fab-color: rgb(var(--color-base-40), 0.15) !important;
    background: none;
  }
}
