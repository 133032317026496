$header-height: 90px;

$box-shadow-0:
  0 0 0 0 rgba(0, 0, 0, 0.2),
  0 0 0 0 rgba(0, 0, 0, 0.14),
  0 0 0 0 rgba(0, 0, 0, 0.12);
$box-shadow-1:
  0 2px 1px -1px rgba(0, 0, 0, 0.2),
  0 1px 1px 0px rgba(0, 0, 0, 0.14),
  0 1px 3px 0px rgba(0, 0, 0, 0.12);
$box-shadow-2:
  0 3px 3px -2px rgba(0, 0, 0, 0.2),
  0 3px 4px 0px rgba(0, 0, 0, 0.14),
  0 1px 8px 0px rgba(0, 0, 0, 0.12);
$box-shadow-3:
  0 6px 6px -3px rgba(0, 0, 0, 0.2),
  0 10px 14px 1px rgba(0, 0, 0, 0.14),
  0 4px 18px 3px rgba(0, 0, 0, 0.12);
$box-shadow-4:
  0 7px 9px -4px rgba(0, 0, 0, 0.2),
  0 14px 21px 2px rgba(0, 0, 0, 0.14),
  0 5px 26px 4px rgba(0, 0, 0, 0.12);
$box-shadow-5:
  0 11px 15px -7px rgba(0, 0, 0, 0.2),
  0 24px 38px 3px rgba(0, 0, 0, 0.14),
  0 9px 46px 8px rgba(0, 0, 0, 0.12);

$item-border-radius: 10px;
