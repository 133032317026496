@import 'variables';
@import 'scrollbar';
@import 'mat-table';
@import 'mat-card';
@import 'material-custom';
@import 'nav';
@import 'modals';
@import 'buttons';
@import 'checkboxes';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $container-padding 0;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.mat-mdc-dialog-container .mat-mdc-dialog-actions {
  justify-content: flex-end;
}

.info-snackbar,
.error-snackbar {
  mat-icon.close {
    color: rgb(var(--color-base-black)) !important;
  }
}

.info-snackbar span {
  color: rgb(var(--color-base-black)) !important;
}

.error-snackbar span {
  font-weight: 500 !important;
  color: rgb(var(--color-error-100)) !important;
}

.input-button-form {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: space-around;

  .invite-input {
    margin-top: 1.2rem;
  }
}
.icon {
  color: rgb(var(--color-base-black));
}

.mat-mdc-card {
  // max-width: 90vw;//fix overflow
  overflow: auto;
}

.buttons {
  gap: 10px;
  display: flex;
}

.mat-mdc-select-panel input {
  box-shadow: rgb(var(--color-base-40)) 0 0 4px;
  height: 25px;
  width: 80%;
  margin: 10px 10%;
}
