@import '../styles/dashboard-vars';
.gridster-item-moving {
  z-index: 9999 !important;
}

gridster {
  background-color: transparent !important;
  .gridster-column,
  .gridster-row {
    //border-color: rgba( var(--color-base-40), 0.4) !important;
    border: none !important;
  }
  .gridster-row {
    background-image: radial-gradient(circle, rgb(var(--color-base-60), 0.05) 3px, transparent 0%);
    background-size: 35px 6px;
    background-position: center;
    background-repeat: repeat-x;

    //border-bottom: unset!important;
  }
  .gridster-column {
    //background-repeat: repeat-y;
  }
  //.gridster-column:not(.gridster-column:first-child) {
  //  border-left: unset!important;
  //}
  .gridster-item-drag {
  }
}

//.gridster-item-resizable-handler
//{
//  border-color: transparent!important;
//}

.gridster-preview {
  border-radius: $item-border-radius;
}
