@import 'material-icons/iconfont/material-icons.scss';
// @import './assets/oem0/theme.scss';
// @import './assets/oem1/theme.scss';
// @import './assets/oem2/theme.scss';
@import '/src/app/shared/styles/shared';
@import '/src/app/pages/dashboard/styles/gridster';

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/assets-common/fonts/static/Inter-Regular.ttf') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('/assets-common/fonts/static/Inter-Medium.ttf') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/assets-common/fonts/static/Inter-SemiBold.ttf') format('woff2');
}

* {
  user-select: none;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
}

body {
  font-weight: 500;
  font-size: 12px;
  //line-height: 1.5;
}

input {
  user-select: auto !important;
}

.selectable {
  user-select: text !important;
  * {
    user-select: text !important;
  }
}

.primary-text {
  color: rgb(var(--color-primary));
  //font-weight: 400;
}

.warning-text {
  color: rgb(var(--color-error-100));
  font-weight: 500;
}

body {
  margin: 0;
  //overflow: overlay;
  overflow-x: hidden;
}

.cdk-global-overlay-wrapper {
  > div {
    //IT messed up modals
    //width: 550px !important;
  }
}

@media screen and (max-width: 599.98px) {
  .cdk-global-overlay-wrapper {
    > div {
      width: 90vw !important;
    }
  }
}

.tooltip-pre {
  white-space: break-spaces;
  div {
    text-align: left;
  }
}

.small-height-toggle {
  .mat-mdc-button-toggle-label-content {
    height: auto;
    line-height: unset;
  }
}

input{
  background: rgb(var(--color-base-white));
}

// Temporary fix; expansion panel is opened, this is issue regarding the problem: https://github.com/angular/components/issues/16534
// And this is comment where I've got this solution: https://github.com/angular/components/issues/11765#issuecomment-1439669577
// Every other solutions wasn't working great
// This should be fixed already https://github.com/angular/components/pull/30119, so commenting out for now
//.ng-animating:not(.mat-expanded)
//  .mat-accordion:not(.mat-expanded)
//  .mat-expansion-panel:not(.mat-expanded)
//  .mat-expansion-panel-content:not(.mat-expanded) {
//  height: 0;
//  visibility: hidden;
//}

.mat-drawer-backdrop.mat-drawer-shown {
  filter: opacity(0.5);
}

.node-name-header {
  font-size: 24px;
  font-weight: 600;
  padding: 6px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  margin: 0;
  height: auto;
}

.hide{
  display: none!important;
}

.no-wrap{
  text-wrap: wrap;
  break-inside: auto;
  overflow-wrap: break-word;
}

@media screen and (max-width: 599.98px) {
  .node-name-header {
    font-size: 20px;
    height: auto;
  }
}
