::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  margin-right: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgb(var(--color-base-50));
}
