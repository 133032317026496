$primary: rgb(var(--color-primary)); //TODO get from OEM
.uplot {
  max-width: 100%;
  position: relative;
}

.u-legend {
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  //margin: 10px 5px 15px;
  margin: 0;
  padding: 0 5px;

  max-height: 75px;
  min-height: 35px;
  overflow: auto;

  .u-series {
    min-width: 50px;
    position: relative;

    &.u-off:before {
      content: '';
      width: 90%;
      height: 1px;
      background: rgb(var(--color-base-60), 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .u-label {
    color: rgb(var(--color-base-60));
  }

  .u-label,
  .u-marker {
    font-weight: 500;
    font-size: 12px;
  }

  .u-value,
  .u-series:first-of-type {
    display: none;
  }

  th::after {
    content: '' !important;
  }
}

.u-title {
  color: black;
}

.u-over {
  // cursor: default !important;
  cursor: pointer;
}

.u-tooltip {
  pointer-events: none;
  display: none;
  //background: rgb(var(--color-base-10));
  background: rgba(var(--color-base-10), .8);
  color: rgb(var(--color-base-bla) ck);
  // position: fixed;
  position: absolute;
  white-space: pre;
  padding: 3px 15px;
  border-radius: 3px;
  //box-shadow: rgb(153, 153, 153) 1px 1px 3px 1px;
  box-shadow: rgb(var(--color-base-40)) 1px 1px 5px 1px;

  //display: flex;
  flex-direction: column;
  z-index: 100;

  .u-tooltip-label {
    text-align: center;
    font-size: 1.1em;
    justify-content: center;

    padding: 2px;
    margin-bottom: 5px;
    border: none;
    flex-direction: column;
    gap: 0;

    & *:nth-child(2) {
      color: grey;
    }
  }

  table {
    tr:first-of-type {
      * {
        text-align: center;
      }
    }

    td {
      border-bottom: rgb(var(--color-base-20)) solid 1px;
      padding: 2px 5px;

      &:first-of-type {
        border: none;
      }
    }

    .total {
      border-bottom-color: rgb(var(--color-base-50));
    }
  }

  span {
    display: flex;
    gap: 10px;
    align-items: center;

    &.u-tooltip-name {
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }

    &.u-tooltip-value {
      width: auto;
      font-weight: 500;
      justify-content: flex-end;
    }
  }

  .hide {
    display: none;
  }
}

.hideAdditional {
  .additional {
    display: none;
  }
}

.u-tooltip-color,
.u-marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}

.u-select {
  background: rgba(102, 102, 102, 0.26);
}

.u-wheel-message,
.u-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(102, 102, 102, 0.26);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    font-size: 30px;
    color: rgb(var(--color-base-black), 0.6);
  }
}

.u-loading {
  background: rgba(102, 102, 102, 0.08);
  z-index: 200;

  > div,
  &.unlocked {
    background-color: unset;
    height: 30px;
    width: 30px;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    animation: rotate 1s cubic-bezier(0.55, 0.06, 0.78, 0.63) infinite;

    border: rgb(var(--color-base-40)) 4px solid;
    border-left: transparent 4px solid;

    position: absolute;
  }

  &.unlocked {
    > div {
      display: none;
    }

    left: unset;
  }
}

//.u-series > *
//{
//  padding:2px;
//}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.dark {
  &.uplot-container {
    background-color: black;
    box-shadow: rgb(var(--color-base-40)) 0px 0px 5px 1px;
    border: $primary solid 2px;
  }

  .u-wheel-message,
  .u-loading {
    background: rgb(var(--color-base-50), 0.4);
  }

  .u-select {
    background: rgb(var(--color-base-white), 0.4);
  }

  .u-title {
    color: rgb(var(--color-base-white));
  }
}

.u-title {
  display: none;
}

.uplot{
  &.loading{
    &:before{
      content: '';
      position: absolute;
      left: 0;
      //top:0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 99;
    }
  }

}

.custom-tooltip{
  pointer-events: none;
  position: fixed;
  background: #616161;
  color: white;
  width: 200px;
  z-index: 999;
  border-radius: 4px;
  padding: 3px;
  font-size: 12px;
}
