mat-card {
  height: auto;
  // height of paginator is ~56 pixels
  max-height: calc(100% - 16px);
  width: auto;
  //border-radius: 30px;
  padding: 16px;
  overflow: hidden;
  border: none !important;
  box-shadow: rgb(var(--color-base-black), 0.2) 0 0 3px -3px !important;
  margin: 5px;
  background: rgb(var(--color-base-white))!important;
}
mat-card-header {
  width: 100%;
  form {
    width: 60%;
    mat-form-field {
      width: 100%;
    }
  }
}
mat-card-footer,
mat-card-content {
  padding: 0 16px;
  width: calc(100% - 32px);
}
mat-card-footer {
  width: 100%;
}

mat-card-footer,
mat-card-header {
  padding: 0;
  flex: 0 0 auto;
}
mat-card-content {
  flex: 1 1 auto;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
}
