.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  // max-width: 83rem;
  // margin-left: auto;
  // margin-right: auto;
}

.list {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mat-mdc-cell {
  width: auto;
  //&.mat-column-buttons
  //{
  //  width: 0!important;
  //}
}


table th {
  font-size: 16px;
  color: rgb(var(--color-primary));
  font-weight: bold;
}

.mat-mdc-paginator{
  background: rgb(var(--color-base-white))!important;
}
.mat-mdc-table {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0 0.5rem 2rem;

  background: rgb(var(--color-base-white))!important;

  //.mat-mdc-row {
  //  border-bottom: none !important;
  //}

  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    border: none;
    user-select: text;
  }

  .mat-sort-header-content {
    text-align: left;
  }

  tr.mat-mdc-row,
  tr.mat-mdc-footer-row,
  tr.mat-mdc-no-data-row {
    height: 60px;

    &:hover {
      //background-color: rgb(var(--color-base-10));
      background-color: rgb(var(--color-primary),.01);
    }
  }
  tr.mat-mdc-no-data-row {
    color: rgba(var(--color-base-black), 0.5);
  }
  .information {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.95rem;

    .icon {
      opacity: 0.45;
      transition: 0.3s all;
    }

    &:hover .icon {
      opacity: 1;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 72px;

    .controls-icon:hover {
      transition: 0.3s all;
      opacity: 0.6;
    }
  }
}

.form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 83rem;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .nav {
    max-width: 58rem;
    width: 100%;
    background-color: rgb(var(--color-base-10));
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    margin: 0 -3rem 2rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;

    &:before,
    &:after {
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
      border: 0.75rem solid transparent;
      border-bottom: 0.75rem solid rgb(var(--color-base-20));
      transform-origin: center;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: -1;
    }

    &:before {
      left: 0;
      transform: translateY(-0.45rem) rotate(135deg) translateX(-0.4rem);
    }

    &:after {
      right: 0;
      transform: translateY(-0.45rem) rotate(-135deg) translateX(0.4rem);
    }
  }
}

.mat-mdc-paginator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.heading {
  color: rgb(var(--color-base-black));
  text-align: center;

  h1 {
    margin-bottom: 3rem !important;
    font-weight: 300;
    position: relative;

    &:after {
      content: '';
      height: 2px;
      width: 2rem;
      position: absolute;
      left: 50%;
      bottom: -1rem;
      transform: translateX(-50%);
      background-color: rgb(var(--color-base-black));
    }
  }
}

.overflow .mat-mdc-tab-body-wrapper {
  max-height: 50vh;
  // overflow:auto;

  > {
    padding-right: 10px;
  }
}

.not-found {
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.54);
  a {
    cursor: pointer;
    text-decoration: underline;
  }
}

$default-padding: 10px;

@media screen and (max-width: 600px) {
  .mat-mdc-table {
    padding: 0;
  }
}

.table-link {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  a {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent;
    position: absolute;
  }
  &:hover {
    opacity: 0.7;
  }
}
