// Snackbar
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background: #fff !important;
  border-radius: 20px !important;
}

.hidden-checkbox {
  .mdc-checkbox {
    display: none;
  }
}

.mat-select-min-width-200 {
  min-width: 200px;
}

mat-snack-bar-container {
  z-index: 999;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0;
}

.mdc-tooltip .mdc-tooltip__surface {
  overflow-y: auto;
  //max-height: unset;
}

mat-paginator mat-form-field {
  padding-bottom: 0;
}

div.header mat-form-field {
  padding-bottom: 0;
}

mat-form-field {
  padding-bottom: 1.25em;

  .mat-mdc-text-field-wrapper {
    border-radius: 10px !important;
    border: rgb(var(--color-primary)) solid 1px !important;
    background: rgb(var(--color-primary), 0.1) !important;
    position: relative;

    .mdc-line-ripple {
      display: none !important;
    }

    .mat-mdc-form-field-focus-overlay {
      display: none;
    }
  }

  &[disabled='true'] .mat-mdc-text-field-wrapper,
  &.mat-form-field-disabled .mat-mdc-text-field-wrapper {
    filter: grayscale(0.7);

    &,
    & * {
      cursor: not-allowed !important;
    }

    mat-icon {
      color: rgb(var(--color-base-30)) !important;
    }
  }

  &.mat-focused {
    .mat-mdc-text-field-wrapper {
      box-shadow: rgb(var(--color-primary)) 1px 1px 3px -1px;
    }
  }

  .mdc-notched-outline {
    display: none;
  }
}

.mat-mdc-paginator {
  .mat-mdc-text-field-wrapper {
    //border-radius: 0!important;
    border: none !important;
    background: none !important;
  }

  .mat-focused .mat-mdc-text-field-wrapper {
    box-shadow: rgb(var(--color-base-40)) 0 0 4px -1px;
  }

  .mat-mdc-paginator-page-size-select {
    width: 70px;
  }
}

mat-button-toggle-group {
  border-radius: 5px !important;
  border: rgb(var(--color-primary)) solid 1px !important;
  background: rgb(var(--color-base-20), 1) !important;

  .mat-button-toggle-label-content {
    color: rgb(var(--color-base-50), 1);
    font-weight: 500;
  }

  mat-button-toggle.mat-button-toggle-checked {
    background-color: rgb(var(--color-primary), 1) !important;

    .mat-button-toggle-label-content {
      color: white;
    }
  }

  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    color: white;
  }
}

.mat-calendar-body button {
  padding: 0;
}

div.mat-mdc-form-field-flex {
  align-items: center !important;
}

.mat-mdc-form-field-icon-suffix {
  display: flex;
}

div.mat-mdc-tab-body-content {
  overflow: hidden;
  //height: 100%;
}

mat-tab-group {
  height: 100%;
  display: flex;

  mat-tab-header {
    flex: 0 0 auto;
  }

  .mat-mdc-tab-body-wrapper {
    flex: 1 1 auto;
    max-height: 100%;
    overflow: auto;
  }
}

.mdc-checkbox__background {
  border-radius: 5px !important;
}

app-profile .mat-mdc-tab-body-content {
  overflow: auto;
}

app-profile .profile-form {
  height: 590px;
}

app-profile .mat-mdc-tab-body-wrapper {
}

app-logger-edit .mat-mdc-tab-body-content,
app-logger-device .mat-mdc-tab-body-content {
  overflow: auto;
}

//app-logger-edit .mat-mdc-tab-body-wrapper,app-logger-device .mat-mdc-tab-body-wrapper{
//
//}

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

@media screen and (max-width: 600px) {
  mat-paginator {
    padding-top: 10px;

    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 30px;
      height: 30px;
      padding: 0;
    }
  }
}

.mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
}

//CALENDAR
.mat-datepicker-content {
  border-radius: 15px !important;
  padding-bottom: 5px;
}

.custom-mat-tooltip {
  max-width: unset !important;
  white-space: pre !important;
  position: fixed;
}

button[disabled],
button.disabled {
  mat-icon {
    color: rgb(var(--color-base-40), 0.3) !important;
  }
}

.mat-tab-padding .mat-mdc-tab-body-content {
  padding: 0 10px;
}

mat-option > span {
  width: 100%;
}

.mat-tab-overflow-body .mat-mdc-tab-body-content {
  overflow: auto !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  width: auto !important;
}


.cdk-overlay-pane {
  width: auto !important;
}


.mat-mdc-select-value-text {
  text-wrap: wrap;
  break-inside: auto;
  overflow-wrap: break-word;
}
